import React from "react";
import {
  makeStyles,
  Divider,
  LinearProgress,
  Tabs,
  Tab,
  useMediaQuery,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";
import { axiosInstance } from "./axios";
import moment from "moment";
import { Spin } from "antd";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { useNavigate, useParams } from "react-router-dom";

const useStyles = makeStyles({
  text: {
    fontWeight: 600,
    fontSize: 12,
    color: "#808080",
  },
  info: {
    fontSize: 16,
    fontWeight: 600,
    color: "#666666",
  },
  layout: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    borderRadius: 5,
    fontSize: 14,
    fontWeight: 600,
    color: "#ffffff",
    padding: "12px 0px",
    textAlign: "center",
    cursor: "pointer",
    margin: "35px 0px ",
  },
  tabContainer: {
    borderBottom: "1px solid #ededed",
  },
  tabRoot: {
    "&$tabSelected": {
      color: "black",
      fontWeight: 700,
    },
    textTransform: "capitalize",
    color: "#999999",
    fontWeight: 700,
    fontSize: 14,
    alignItems: "unset",
    justifyContent: "flex-start",
    minWidth: 80,
  },
  tabSelected: {},
  indicator: {
    width: 80,
  },
  boxUpdate: {
    padding: "20px 25px",
    border: "1px solid #ECECEC",
    borderRadius: 12,
    margin: "16px 0px",
  },
  page: {
    "&$selected": {
      color: "white",
      backgroundColor: "#2DBE78",
      "&:hover": {
        backgroundColor: "#2DBE78",
      },
    },
  },
  selected: {},
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const DonationDetail = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const [value, setValue] = React.useState(2);
  const md = useMediaQuery("(max-width:768px)");
  const [isLoading, setIsLoading] = React.useState(true);
  const [loadingDonation, setLoadingDonation] = React.useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [campaign, setCampaign] = React.useState(null);
  const [donations, setDonations] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(1);
  const [totalDonator, setTotalDonator] = React.useState(0);

  const currentDate = new Date();
  const expiredDate = new Date(campaign?.expiredAt);

  const DifferenceInTime = expiredDate.getTime() - currentDate.getTime();

  const DifferenceInDays = Math.ceil(DifferenceInTime / (1000 * 3600 * 24));
  const [redirectUrl, setRedirectUrl] = React.useState("");

  const getCampaign = async () => {
    const fetchData = await axiosInstance.get(
      `/customer/ecommerce/products/${id}`
    );

    setCampaign(fetchData.data.data);
    getDonations(currentPage, fetchData?.data?.data?.id);
    setIsLoading(false);
  };

  const getDonations = async (currentPage, productId) => {
    setLoadingDonation(true);
    const fetchData = await axiosInstance.get(
      `/customer/berbagi/donations?page=${currentPage}&perPage=5&productIds=${productId}`
    );
    setDonations(fetchData.data.data);
    setTotalPage(fetchData.data.meta.totalPage);
    setTotalDonator(fetchData.data.meta.totalData);
    setLoadingDonation(false);
  };

  React.useEffect(() => {
    if (id) {
      getCampaign();
      setRedirectUrl(window.location.href);
    }
  }, [id]);

  return (
    <Spin spinning={isLoading}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            marginTop: md ? 110 : 130,
            marginBottom: 16,
            maxWidth: 850,
            width: "100%",
            padding: "0px 16px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: md ? "unset" : "space-between",
              alignItems: md ? "center" : "unset",
              flexDirection: md ? "column" : "row",
            }}
          >
            <div
              style={{
                width: md ? "95%" : "50%",
                marginBottom: md ? 30 : 0,
                // height: 350,
                display: "flex",
                justifyContent: md ? "center" : "flex-start",
              }}
            >
              <img
                src={campaign?.image?.url}
                alt="image"
                style={{
                  width: "100%",
                }}
              />
            </div>

            <div style={{ width: md ? "90%" : "50%", marginLeft: md ? 0 : 20 }}>
              <div
                style={{
                  fontSize: 24,
                  fontWeight: 600,
                  color: "#1A1A1A",
                  marginBottom: 22,
                }}
              >
                {campaign?.name}
              </div>
              <div className={classes.layout}>
                <div
                  style={{ fontSize: 12, fontWeight: 600, color: "#808080" }}
                >
                  Kategori:{" "}
                  <span style={{ color: "#2DBE78" }}>
                    {campaign?.categories?.map((data) => data.name).join(",")}
                  </span>
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                      color: "#595959",
                      marginLeft: 8,
                    }}
                  >
                    Santren Koding
                  </div>
                </div>
              </div>
              <Divider style={{ margin: "14px 0px" }} />

              <div className={classes.layout}>
                <div>
                  <div className={classes.text}>Terkumpul</div>
                  <div
                    style={{
                      fontSize: 14,
                      color: "#2DBE78",
                      fontWeight: 600,
                      marginTop: 10,
                    }}
                  >
                    Rp{" "}
                    {campaign?.totalSales
                      ?.toString()
                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}{" "}
                    {campaign?.target && (
                      <span
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          color: "#808080",
                        }}
                      >
                        (
                        {(
                          (campaign?.totalSales / campaign?.target) *
                          100
                        ).toFixed(2)}
                        %)
                      </span>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  {campaign?.target && (
                    <>
                      <div className={classes.text}>Dana Dibutuhkan</div>
                      <div
                        style={{
                          fontWeight: 14,
                          color: "#808080",
                          fontWeight: 700,
                          marginTop: 10,
                        }}
                      >
                        Rp{" "}
                        {campaign?.target
                          ?.toString()
                          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                      </div>
                    </>
                  )}
                </div>
              </div>

              <br></br>

              <div className={classes.layout}>
                <div>
                  <div className={classes.text}>Dana Tersalurkan</div>
                  <div
                    style={{
                      fontSize: 14,
                      color: "#2DBE78",
                      fontWeight: 600,
                      marginTop: 10,
                    }}
                  >
                    Rp{" "}
                    {campaign?.totalExpense
                      ?.toString()
                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}{" "}

                      <span
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          color: "#808080",
                        }}
                      >
                        (
                        {(
                          (campaign?.totalExpense / campaign?.totalSales) *
                          100
                        ).toFixed(2)}
                        %)
                      </span>

                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <div className={classes.text}>Sisa Saldo</div>
                  <div
                    style={{
                      fontWeight: 14,
                      color: "#808080",
                      fontWeight: 700,
                      marginTop: 10,
                    }}
                  >
                    Rp{" "}
                    {campaign?.totalBalance
                      ?.toString()
                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                  </div>
                </div>
              </div>

              <LinearProgress
                variant="determinate"
                value={
                  campaign?.target
                    ? (campaign?.totalSales / campaign?.target) * 100
                    : 100
                }
                classes={{
                  barColorPrimary: classes.activeBar,
                }}
                style={{ backgroundColor: "#c4c4c4", margin: "15px 0px" }}
              />

              <div className={classes.layout}>
                <div className={classes.text}>{totalDonator} Donatur</div>

                {campaign?.expiredAt === null ? (
                  <div>&#8734; Hari Lagi</div>
                ) : (
                  <div
                    style={{ fontSize: 14, fontWeight: 700, color: "#808080" }}
                  >
                    {DifferenceInDays > 0
                      ? DifferenceInDays + " Hari Lagi"
                      : "Expired"}
                  </div>
                )}
              </div>
              <div
                className={classes.button}
                style={{
                  backgroundColor:
                    campaign?.expiredAt === null
                      ? "#2DBE78"
                      : DifferenceInDays < 0
                      ? "grey"
                      : "#2DBE78",
                }}
                onClick={() => {
                  if (campaign?.expiredAt === null) {
                    setIsLoading(true);
                    navigate(`/campaigns/donation/${campaign?.slug}`);
                    return;
                  }
                  if (DifferenceInDays > 0) {
                    setIsLoading(true);
                    navigate(`/campaigns/donation/${campaign?.slug}`);
                    return;
                  }
                }}
              >
                Donasi Sekarang
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{ fontWeight: 700, fontSize: 12, color: "#808080" }}
                >
                  Share
                </div>
                <div style={{ display: "flex" }}>
                  {" "}
                  <TwitterShareButton url={redirectUrl}>
                    <TwitterIcon size={32} bgStyle={{ fill: "#23aced" }} />
                  </TwitterShareButton>
                  <WhatsappShareButton
                    url={redirectUrl}
                    style={{ margin: "0px 12px" }}
                  >
                    <WhatsappIcon size={32} bgStyle={{ fill: "#39d366" }} />
                  </WhatsappShareButton>
                  <FacebookShareButton url={redirectUrl}>
                    <FacebookIcon size={32} bgStyle={{ fill: "#3b5998" }} />
                  </FacebookShareButton>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: 40,
              marginBottom: 15,
            }}
          >
            <div style={{ width: "100%" }}>
              <Tabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
                aria-label="disabled tabs example"
                classes={{
                  indicator: classes.indicator,
                  flexContainer: classes.tabContainer,
                }}
              >
                <Tab
                  label="Detail"
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                  }}
                  style={{ marginRight: md ? 0 : 30 }}
                  {...a11yProps(0)}
                />
                <Tab
                  label="Update"
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                  }}
                  style={{ marginRight: md ? 0 : 30 }}
                  {...a11yProps(1)}
                />
                <Tab
                  label={`Donatur ${totalDonator}`}
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                  }}
                  style={{ marginRight: md ? 0 : 30 }}
                  {...a11yProps(2)}
                />
              </Tabs>
              <TabPanel value={value} index={0}>
                <div
                  style={{
                    fontSize: 14,
                    color: "#4d4d4d",
                    fontWeight: 700,
                    margin: "20px 0px",
                  }}
                >
                  {campaign?.name}
                </div>
                <div
                  style={{ fontSize: 14, color: "#595959", fontWeight: 600 }}
                  dangerouslySetInnerHTML={{ __html: campaign?.description }}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                {campaign?.updates?.length > 0 ? (
                  <>
                    {campaign?.updates?.map((kampanye) => (
                      <div className={classes.boxUpdate}>
                        <div
                          style={{
                            fontWeight: 600,
                            fontSize: 12,
                            color: "#999999",
                          }}
                        >
                          {moment(kampanye.createdAt).format("LLL")}
                        </div>
                        <div
                          style={{
                            color: "#4d4d4d",
                            fontSize: 14,
                            fontWeight: 600,
                            marginTop: 8,
                          }}
                        >
                          {kampanye?.title}{" "}
                          {kampanye?.expense
                            ?.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {kampanye?.images?.map((image) => (
                            <img
                              alt="img"
                              src={image}
                              style={{
                                marginRight: 8,
                                width: 100,
                                height: 100,
                              }}
                            />
                          ))}
                        </div>
                        <div
                          style={{
                            color: "#4d4d4d",
                            fontSize: 14,
                            fontWeight: 600,
                            marginTop: 8,
                          }}
                          dangerouslySetInnerHTML={{
                            __html: kampanye?.description,
                          }}
                        />
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    <h4 style={{ textAlign: "center", padding: "16px 0px" }}>
                      Belum ada update!
                    </h4>
                  </>
                )}
              </TabPanel>
              <TabPanel value={value} index={2}>
                <div className={classes.boxUpdate}>
                  <Spin spinning={loadingDonation}>
                    {donations.length > 0 ? (
                      <>
                        {donations.map((donatur) => (
                          <>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                margin: "10px 0px",
                              }}
                            >
                              <div style={{ marginLeft: 15 }}>
                                <div
                                  style={{
                                    fontSize: 14,
                                    color: "#4d4d4d",
                                    fontWeight: 600,
                                  }}
                                >
                                  {donatur.donator}
                                </div>
                                <div
                                  style={{
                                    color: "#2DBE78",
                                    fontSize: 14,
                                    fontWeight: 700,
                                  }}
                                >
                                  Rp{" "}
                                  {donatur.total
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                </div>
                                {donatur?.customerNote && (
                                  <div
                                    style={{
                                      fontSize: 12,
                                      fontWeight: 600,
                                    }}
                                  >
                                    {donatur?.customerNote}
                                  </div>
                                )}
                                <div
                                  style={{
                                    fontSize: 10,
                                    color: "#999999",
                                    fontWeight: 600,
                                  }}
                                >
                                  {moment(donatur.paidAt).format("LLL")}
                                </div>
                              </div>
                            </div>
                            <Divider />
                          </>
                        ))}
                      </>
                    ) : (
                      <h4 style={{ textAlign: "center", padding: "16px 0px" }}>
                        Belum ada donatur
                      </h4>
                    )}
                  </Spin>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 20,
                    }}
                  >
                    <Pagination
                      count={totalPage}
                      onChange={(event, page) => {
                        setCurrentPage(page);
                        getDonations(page, campaign?.id);
                      }}
                      page={currentPage}
                      size="small"
                      renderItem={(item) => (
                        <PaginationItem
                          {...item}
                          classes={{
                            page: classes.page,
                            selected: classes.selected,
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </TabPanel>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default DonationDetail;
